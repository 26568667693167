import React from 'react';

import { Container, Details } from './styled';

const Recommendation = ({ albumName, artistName, coverImage }) => {
  return (
    <Container>
      <img src={coverImage.url} alt='' />
      <Details>
        <h4>{artistName}</h4>
        <h4>{albumName}</h4>
      </Details>
    </Container>
  );
};

export default Recommendation;
