import styled from 'styled-components';

export const Body = styled.div`
  width: 900px;
  margin: 0 auto;
  transform: translate(-140px, -40px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    transform: none;
  }
`;

export const RecommendationsWrap = styled.div`
  width: 100%;
  h2 {
    margin: 120px 0 40px;
    font-size: 28px;
    min-width: 100%;
    text-align: center;
  }
`;

export const Recommendations = styled.div`
  display: flex;
  width: 600px;
  margin: 0 auto;
`;

export const Container = styled.div`
  min-height: 100px;
  transition: transform 450ms;
  padding: 0 10px;
  margin-bottom: 40px;
  margin-left: 8px;
  img {
    height: 162px;
    width: 162px;
    border-radius: 15px;
    object-fit: cover;
  }
  &:hover {
    transform: scale(1.08);
  }
`;

export const Details = styled.div`
  text-align: center;
  width: 162px;
  h4 {
    font-size: 14px;
    margin: 10px 0;
    &:first-of-type {
      margin-top: 15px;
    }
    &:last-of-type {
      font-weight: lighter;
      font-style: italic;
    }
  }
`;
