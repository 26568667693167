import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'layouts/MainLayout';
import { DarkScheme } from 'helpers/navColors';
import { Banner, SEO, TextBody } from 'components';

import Recommendation from './Recommendation';
import { Body, Recommendations, RecommendationsWrap } from './styled';

const GuideTemplate = ({ data }) => {
  const {
    title,
    coverImage,
    body,
    authors,
    albumRecommendations,
  } = data.strapiGenreGuides;

  const bannerConfig = {
    title: title,
    image: coverImage,
    type: 'guide',
  };

  return (
    <MainLayout navColor={DarkScheme}>
      <SEO
        title={`A Brief Guide to ${title}`}
        description={`The Glow’s guide to the history and legacy of ${title}.`}
        image={coverImage.url}
        imageShare={coverImage.url}
        isBlogPost
      />

      <Banner {...bannerConfig} />

      <Body>
        <TextBody body={body} authors={authors} />

        <RecommendationsWrap>
          <h2>Top {title} Albums</h2>
          <Recommendations>
            {albumRecommendations.slice(0, 3).map((albumRecommendation) => (
              <Recommendation {...albumRecommendation} />
            ))}
          </Recommendations>
        </RecommendationsWrap>
      </Body>
    </MainLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    strapiGenreGuides(slug: { eq: $slug }) {
      title
      coverImage {
        url
      }
      body
      authors {
        englishName
        slug
      }
      albumRecommendations {
        albumName
        artistName
        coverImage {
          url
        }
      }
    }
  }
`;

export default GuideTemplate;
